import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { getContentExprtContents } from "../../../services/contentServices";
import Pagination from "../../includes/Pagination";
import GettingDataSpinner from "../../includes/Spinners/GettingDataSpinner";
import WriterContentsTable from "./WriterContentsTable";

const WriterContents = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [refresh, setRefresh] = useState(false);

  const [contents, setContents] = useState([]);
  const [results, setResults] = useState(0);

  const user = useSelector((state) => state.user);

  const [searchName, setSName] = useState("");
  const [searchStatus, setSStatus] = useState("");

  let searchstr = `page=${page}&limit=${limit}`;
  useEffect(() => {
    fetchContent();
  }, [page, user, limit, refresh]);

  const fetchContent = async () => {
    try {
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);
      const token = user.token;
      if (!token) return;
      const { data } = await getContentExprtContents(token, searchstr);

      setResults(data.results);
      setResults(data.results);
      setContents(data.contents);
      setDialog(false);
    } catch (e) {
      setDialog(false);
      toast.error(e.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const SearchHandler = () => {
    if (page > 0) {
      searchstr = `page=${page}`;
    } else if (page < 0) {
      searchstr = searchstr + `page=1`;
    }
    if (searchName != "") searchstr = searchstr + `&subject=${searchName}`;
    if (searchStatus != "")
      searchstr = searchstr + `&status.stage=${searchStatus}`;

    fetchContent();
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  return (
    <div className="h-full overflow-y-scroll ">
      <div className="p-2 mb-8 text-sm">
        <div className="p-2 w-full flex gap-4 flex-wrap rounded bg-white justify-between items-center">
          <div className="col-span-7 sm:col-span-3  lg:col-span-3 flex items-center gap-2">
            <p>عنوان</p>
            <input
              onChange={(e) => onChangeHandler(e, setSName)}
              type="text"
              className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100"
            />
          </div>
          <div class="col-span-7 sm:col-span-4 lg:col-span-3 flex items-center gap-2">
            <p>وضعیت:</p>
            <select class="p-2 w-full lg:w-auto  xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100">
              <option value="item-1" disabled="">
                وضعیت مورد نظر خود را انتخاب کنید.
              </option>
              <option value="">همه محتوا ها</option>
              <option value="Assing to be Written">اساین شده به نویسنده</option>
              <option value="Assing to be Written">اساین شده به نویسنده</option>
            </select>
          </div>
          <div className="flex gap-2 flex-wrap">
            <button
              className="py-1 px-4 bg-Primary text-white rounded"
              onClick={() => {
                setRefresh(!refresh);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
            <button
              className="py-1 px-4 bg-green text-white rounded"
              onClick={() => SearchHandler()}
            >
              جستجو
            </button>
          </div>
        </div>
      </div>
      <WriterContentsTable
        setDialog={setDialog}
        setDialogClasses={setDialogClasses}
        userRole={"customer"}
        contents={contents}
        setContents={setContents}
      />
      <Pagination
        results={results}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
      />
    </div>
  );
};

export default WriterContents;
