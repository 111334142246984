const LoadingSpinner = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-6">
        <div
          className={`inline-block h-20 w-20  border-8 text-green  animate-spin rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
          role="status"
        >
        </div>
        <p className={`text-lg !overflow-hidden !whitespace-nowrap  ![clip:rect(0,0,0,0)] `}>
        در حال ارسال درخواست ...
        </p>
      </div>
    );
  };
  
  export default LoadingSpinner;
  