import { useEffect } from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { setUser } from "../../features/user/userSlice";
import { serverLogin } from "../../services/authServices";
import Spinner from "../includes/Spinner";

const Login = () => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {}, [redirect]);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (phone === "" || password === "") {
      setLoading(false);
      return toast.info("لطفا رمز عبور و نام کاربری را وارد کنید.");
    }

    if (loading) return;

    try {
      const { data } = await serverLogin(phone, password);
      store.dispatch(
        setUser({
          name: data.user.name || data.user.phoneNumber,
          token: data.token,
          email: data.email,
          website: data.website,
          photo: data.user.photo,
          role: data.user.role,
          _id: data.user._id,
        })
      );
      cookies.set("auth", data.token, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
      toast.success("خوش آمدید");
      setRedirect(true);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message?err.response.data.message:err.message);
    }

    setLoading(false);
  };

  return (
    <>
      {redirect ? <Navigate to="/" /> : ""}
      <form
        action=""
        className="flex flex-col gap-8 h-[237px] justify-between relative"
      >
        {/* <div className=" absolute h-full w-full backdrop-blur-sm  z-20">{loading ? <Spinner /> : ""}</div> */}
        <div className="flex justify-between items-center">
          <label htmlFor="phoneNumber" className="w-[max-content] bold">
            شماره تلفن:
          </label>
          <input
            name="phoneNumber"
            onChange={(e) => onChangeHandler(e, setPhone)}
            type="text"
            readOnly={loading}
            placeholder="09110000000"
            className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none  p-2 w-[80%] rounded text-primary backdrop-blur-sm "
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <label htmlFor="password" className="w-[max-content] bold">
            رمز عبور:
          </label>
          <input
            onChange={(e) => onChangeHandler(e, setPassword)}
            name="password"
            type="password"
            readOnly={loading}
            placeholder="***********"
            className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none  p-2 w-[80%] rounded text-primary backdrop-blur-sm"
          />
        </div>
        <button
          type="submit"
          readOnly={loading}
          className="p-2 bg-orange text-white text-center rounded flex items-center justify-center gap-4"
          onClick={handleSubmit}
        >
          {loading ? <Spinner /> : ""}
          <span> ورود به پنل</span>
        </button>
      </form>
    </>
  );
};

export default Login;
