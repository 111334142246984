import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import {
  caculateAmount,
  caculatePrice,
  setCard,
  setStateDiscount,
} from "../../features/card/cardSlice";
import { checkDiscount } from "../../services/discountServices";
import { handlePayment } from "../../services/shopServices";
import Spinner from "../includes/Spinner";

const Card = () => {
  const cookies = new Cookies();

  const [discount, setDiscount] = useState("");
  const [discountinput, setDiscountInput] = useState(false);

  const card = useSelector((state) => state.card) || [];
  const user = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [discountLoading, setDiscountLoading] = useState(false);

  useEffect(() => {
    if (card.discount.discount_code) {
      setDiscount(card.discount.discount_code);
      setDiscountInput(true);
    }
    caculateprice();
    setProducts(card.products);
  }, [card]);

  const discountHandler = async () => {
    if (discountLoading) return;
    try {
      if (discount.trim() === "")
        return toast.info("لطفا کد تخفیف را وارد کنید!");
      setDiscountLoading(true);
      const data = await checkDiscount(discount, products);
      if (data.status == 200) {
        setDiscountInput(true);
        toast.success("کد تخفیف شما ثبت شد");
        store.dispatch(
          setStateDiscount({
            discount_code: data.data.discount.discount_code,
            maxDiscount: data.data.discount.maxDiscount,
            percent: data.data.discount.percent,
          })
        );
        cookies.set("discount", {
          discount_code: data.data.discount.discount_code,
          maxDiscount: data.data.discount.maxDiscount,
          percent: data.data.discount.percent,
        });
      }

      setDiscountLoading(false);
    } catch (error) {
      setDiscountLoading(false);

      return toast.error(error.response.data.message);
    }
  };
  const deleteDiscount = () => {
    if (cookies.get("discount")) {
      cookies.remove("discount");
    }
    if (discount !== "") {
      store.dispatch(setStateDiscount({}));
    }
    setDiscount("");
    setDiscountInput(false);
  };

  const removeItemById = (id) => {
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        const copyProduct = [...products];
        copyProduct.splice(i, 1);
        setProducts(copyProduct);
        cookies.set("card", copyProduct);
        store.dispatch(setCard(copyProduct));
      }
    }
  };

  const caculateprice = () => {
    store.dispatch(caculatePrice());
    store.dispatch(caculateAmount());
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const paymentHandler = async () => {
    if (submitLoading) return;
    try {
      if (card.products.length <= 0) {
        return toast.info("سبد خرید شما خالی است");
      }
      const token = user.token;
      setSubmitLoading(true);
      const data = await handlePayment(token, {
        discount_code: card.discount.discount_code,
        prodList: card.products,
      });
      if (data.data.redirect) {
        setSubmitLoading(false);
        window.location.replace(data.data.redirect);
      } else {
        setSubmitLoading(false);

        toast.info("خطایی رخ داده است. در صورت تکرار با پشتیبانی تماس بگیرید");
      }
    } catch (error) {
      setSubmitLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="container h-full overflow-y-scroll ">
      <div className="p-2 grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-8 lg:col-span-7 xl:col-span-9">
          <div className="flex flex-col   p-2 text-[14px] xl:text-md  mb-6">
            <div className=" grid-cols-12 mb-2 hidden lg:grid items-right gap-2">
              <div className="col-span-12  flex  flex-col lg:flex-row lg:items-center gap-2">
                <div className="flex gap-2 lg:w-[40%] pr-2">
                  <p className="text-Primary">محصول</p>
                </div>
                <hr className="opacity-50 mb-2 " />
                <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
                  <div className="col-span-4 text-center">قیمت کل:</div>
                  <div className=" lg:col-span-4 text-center">تعداد:</div>
                  <div className=" lg:col-span-2 text-center">نوع محصول:</div>
                  <div className=" lg:col-span-2 text-center">عملیات ها:</div>
                </div>
              </div>
            </div>
            <hr className="opacity-50 mb-4 hidden lg:block" />

            <div className="grid grid-cols-12 mb-2 items-right gap-4">
              {card.products.map((el) => (
                <div className="col-span-12  flex  flex-col lg:flex-row lg:items-center gap-2 bg-white rounded-lg p-2">
                  <div className="flex gap-2 lg:w-[40%]">
                    <Link to={`/shop/${el.slug}`} className="text-Primary ">
                      {el.name ? el.name : "این محتوا فاقد موضوع است."}
                    </Link>
                  </div>
                  <hr className="opacity-50 mb-2 " />
                  <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
                    <div className="flex items-center  sm:col-span-12 lg:col-span-4 lg:text-center">
                      <p className="w-[150px] lg:hidden"> قیمت کل:</p>
                      <p className="p-1  w-full bg-gray-100 rounded">
                        {el.price}
                      </p>
                    </div>
                    <div className="flex items-center  sm:col-span-12  lg:col-span-4 lg:text-center">
                      <p className="w-[150px] lg:hidden"> تعداد:</p>
                      <p className="p-1  w-full bg-gray-100 rounded ">
                        {el.count}
                      </p>
                    </div>
                    <div className="flex items-center  sm:col-span-12  lg:col-span-2 lg:text-center w-full">
                      <p className="w-[150px] lg:hidden ">تعداد کلمات:</p>
                      <p className="p-1  w-full bg-gray-100 rounded">
                        {" "}
                        {el.wordCount}
                      </p>
                    </div>
                    <div className="flex items-center  sm:col-span-12  lg:col-span-2">
                      <div className="p-1 w-full hidden lg:flex gap-2 justify-center items-center">
                        <button onClick={() => removeItemById(el.id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr className="opacity-50 mb-2 lg:hidden" />
                  <div className="flex gap-2 justify-end items-center  sm:col-span-12 lg:hidden">
                    
                    <button className="px-2 py-1 rounded bg-secondary bg-opacity-15 text-secondary " onClick={() => removeItemById(el.id)}>
                      حذف
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4  lg:col-span-5 xl:col-span-3">
          <div className="flex flex-col p-4 rounded bg-white gap-4 ">
            <h2 className=" font-bold text-dark-200 ">
              سفارش <span className="text-orange"> تولید محتوا</span>
            </h2>
            <div class="flex bg-background rounded overflow-hidden w-full">
              <input
                onChange={(e) => onChangeHandler(e, setDiscount)}
                type="text"
                class="p-1 w-full border-none outline-none bg-background text-alternative "
                value={discount}
                readOnly={discountinput}
              />
              <>
                {discountLoading ? (
                  <button
                    className="py-1 px-2 bg-project-cyan bg-opacity-50 text-project-cyan font-bold"
                    disabled={true}
                  >
                    <Spinner />
                  </button>
                ) : (
                  <>
                    {card.discount.discount_code ? (
                      <button
                        className="py-1 px-2 bg-orange text-white"
                        onClick={deleteDiscount}
                        disabled={!discountinput}
                      >
                        حذف
                      </button>
                    ) : (
                      <button
                        className="py-1 px-2 bg-green text-white"
                        onClick={discountHandler}
                        disabled={discountinput}
                      >
                        ثبت
                      </button>
                    )}
                  </>
                )}
              </>
            </div>
            <hr className="opacity-50" />
            <div class="flex items-center">
              <p class={`w-[150px]`}>هزینه کل:</p>
              <p
                class={`text-2xl  ${
                  card.discount.discount_code ? "line-through" : ""
                }`}
              >
                {card.price.toLocaleString()}
              </p>
            </div>
            <div class="flex items-center">
              <p class="w-[150px]">هزینه نهایی:</p>
              <p class="text-2xl ">{card.amount.toLocaleString()}</p>
            </div>
            <hr className="opacity-50" />

            <button
              className="bg-Primary text-white p-1 w-full rounded flex justify-center gap-4 items-center"
              onClick={paymentHandler}
            >
              {submitLoading ? <Spinner /> : ""}
              <p>ثبت سفارش</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
