import { useEffect, useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { getContentProjects } from "../../../services/contentServices";

const ProjectFilter = ({ setProject, project, role }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [projects, setProjects] = useState([]);
  const [web, setWeb] = useState("");
  let searchsQuery = `page=${page}&limit=${limit}`;
  const user = useSelector((state) => state.user);

  useEffect(() => {
    projectsHandler();
    setWeb(project)
  }, [user]);

  const projectsHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      const { data } = await getContentProjects(token, "", role);
      setProjects(data.websitesLists);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">فیلتر پروژه ها</p>
        <button
          className=" text-red-500"
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col gap-4 ">
        {projects.map((el) => (
          <button
            onClick={() => {
                if( web === el.siteName){
                    setProject("")
                    setWeb("")
                    
                }else{
                    setProject(el.siteName);
                    setWeb(el.siteName)
                    
                }
            }}
            className={`p-1 w-full  text-white font-bold rounded ${
                web === el.siteName ? "bg-green" : "bg-cyan-500"
            }`}
          >
            {/* needs improvement */}
            {el.siteName
              .replace(/^https?:\/\/(www\.)?/, "")
              .replace(/(\.ca?)(\/?)/, "")}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProjectFilter;
