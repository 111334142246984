import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ViewContext } from "../../context/ViewContext";
import { getProducts } from "../../services/shopServices";
import Pagination from "../includes/Pagination";
import GettingDataSpinner from "../includes/Spinners/GettingDataSpinner";

const Shop = () => {
  const user = useSelector((state) => state.user);
  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const [page, setPage] = useState(1);
  const [results, setResults] = useState(0);
  const [limit, setLimit] = useState(10);

  const [products, setProducts] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [category, setCategory] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    fetchProductHandler();
  }, [refresh, page, user]);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const fetchProductHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);

      const filter = `limit=${limit}${name !== "" ? `&name=${name}` : ""}${
        category !== "" ? `&category=${category}` : ""
      }${page != 1 && page > 1 ? `&page=${page}` : `&page=1`}`;

      if (user.token === "") return;
      const { data } = await getProducts(token, filter);
      console.log(data);
      setProducts(data.products);
      setDialog(false);
    } catch (error) {
      setDialog(false);
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
  };

  return (
    <div className="h-full overflow-y-scroll">
      <div className="grid grid-cols-12 p-4">
        <div className="col-span-12 lg:col-span-8">
          <div className="flex flex-col gap-2 mb-4">
            <div className="flex gap-2 text-gray-500 ">
              <Link to={"#"}>خانه</Link> /<Link to={"#"}>فروشگاه</Link>
            </div>
            <h1 className="text-[2rem]">
              فروشگاه <span className="text-orange"> مدیر محتوا</span>
            </h1>
          </div>
          <div class="grid  grid-cols-4 lg:grid-cols-3  gap-4 mb-8">
            {products.map((el) => (
              <div class="col-span-4 md:col-span-2 lg:col-span-1 bg-white shadow-regular p-2 rounded flex flex-col gap-4">
                <img
                  src={
                    el.imageCover && el.imageCover != ""
                      ? el.imageCover
                      : "/img/post-img.webp"
                  }
                  alt=""
                  class="w-full rounded"
                />
                <p class="text-lg text-title">{el.name}</p>
                <Link
                  to={`/shop/${el.slug}`}
                  class="bg-green p-1 rounded text-sm text-white"
                  href="/shop/desired-count-content"
                >
                  مشاهده و سفارش محصول
                </Link>
              </div>
            ))}
          </div>
          <Pagination
            page={page}
            setPage={setPage}
            results={results}
            setResults={setResults}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
        <div className="col-span-12 lg:col-span-4 flex flex-col gap-4">
          <div class=" p-2 bg-white rounded">
            <div
              action=""
              class="bg-background rounded overflow-hidden  flex w-full"
            >
              <input
                type="text"
                placeholder="جستجو محصول"
                class="w-full border-none outline-none p-1 bg-background "
                value={name}
                onChange={(e) => {
                  onChangeHandler(e, setName);
                }}
              />
              <button
                class="p-1 hover:text-Primary transition-all duration-[.3s]"
                onClick={() => setRefresh(!refresh)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div class=" p-2 flex items-center gap-2 bg-white rounded">
            <p class="w-[250px] text-sm ">مرتب سازی بر اساس: </p>
            <select name="" id="" class="bg-background py-1 px-2 w-full">
              <option value="content" disabled="">
                محتوا ها
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
