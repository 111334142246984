import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  serverRegister,
  serverResendCode,
  serverVerifyPhone,
} from "../../services/authServices";
import Spinner from "../includes/Spinner";

const SignUp = () => {
  // ------------------ variables -------------------------------

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(true);
  const [username, setUsername] = useState(true);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [codeSendedAt, setTime] = useState(Date.now());

  // ------------------ functions -------------------------------

  // #handelSignUp verify phone sign up with sending code, password and password confirm to the server
  const handelSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (code === "" || password === "" || passwordConfirm === "" || email==="") {
      setLoading(false);
      return toast.info("لطفا فیلد ها را به درستی پر کنید.");
    }
    try {
      const { data } = await serverVerifyPhone(code, password, passwordConfirm,email);
      setLoading(false);
      setRedirect(true);
      return toast.success("ثبت نام شما انجام شد.");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };

  // #handleResendCode send a new code to the number
  const handleResendCode = async (e) => {
    e.preventDefault();
 
    if ((Date.now() - codeSendedAt) < 120000)
      return toast.info("قبل از ارسال کد بعدی باید دو دقیقه صبر کنید.");

    try {
      const { data } = await serverResendCode(phone);
      setTime(Date.now());
      toast.success("کد احراز هویت شما مجددا ارسال شد.");
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };
  // #signupHandeler send phone number to the server for sign up and sending code to the number
  const signupHandeler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (phone === "" || username =="") {
      setLoading(false);
      return toast.info("لطفا شماره تلفن یا نارم کاربری را به درستی وارد کنید");
    }
    try {
      const { data } = await serverRegister({phone:phone,username:username});
      setView(false);
      setLoading(false);
      return toast.success("کد احراز هویت برای شما ارسال شد");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };

  // #onChangeHandler handel the input changes
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  // ------------------ View -------------------------------

  return (
    <>
      {redirect ? <Navigate to="/auth/login" /> : ""}
      {view ? (
        <form
          action=""
          className="flex flex-col gap-12 h-[237px] justify-between"
        >
          <div className="flex justify-between items-center mb-8">
            <label htmlFor="phoneNumber" className="w-[120px] bold">
              شماره تلفن:
            </label>
            <input
              readOnly={loading}
              onChange={(e) => {
                onChangeHandler(e, setPhone);
              }}
              name="phoneNumber"
              type="text"
              placeholder="09110000000"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none border-secondary p-2 w-[80%] rounded text-primary backdrop-blur-sm "
            />
          </div>
          <div className="flex justify-between items-center mb-8">
            <label htmlFor="username" className="w-[120px] bold">
              نام کاربری:
            </label>
            <input
              readOnly={loading}
              onChange={(e) => {
                onChangeHandler(e, setUsername);
              }}
              name="username"
              type="text"
              placeholder="username"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none border-secondary p-2 w-[80%] rounded text-primary backdrop-blur-sm "
            />
          </div>
          <button
            className="p-2 bg-orange text-white text-center rounded flex items-center gap-2 justify-center"
            onClick={signupHandeler}
            disabled={!view}
          >
            {loading ? <Spinner /> : ""}
            <span>ثبت نام در مدیر محتوا</span>
          </button>
        </form>
      ) : (
        <form action="" className="flex flex-col gap-4 ">
          <div className="flex justify-between items-center">
            <label htmlFor="code" className="w-[120px] bold">
              کد ارسال شده
            </label>
            <input
              readOnly={loading}
              name="code"
              value={code}
              type="text"
              onChange={(e) => onChangeHandler(e, setCode)}
              placeholder="1234"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none  p-2 w-[80%] rounded text-primary backdrop-blur-sm "
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <label htmlFor="password" className="w-[120px] bold">
              رمز عبور
            </label>
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setPassword)}
              name="password"
              type="password"
              placeholder="***********"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none  p-2 w-[80%] rounded text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <label htmlFor="passwordConfirm" className="w-[120px] bold">
              تکرار رمز عبور
            </label>
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setPasswordConfirm)}
              name="passwordConfirm"
              type="password"
              placeholder="***********"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none  p-2 w-[80%] rounded text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <label htmlFor="password" className="w-[120px] bold">
              ایمیل
            </label>
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setEmail)}
              name="email"
              type="email"
              placeholder="your@gmail.com"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none  p-2 w-[80%] rounded text-primary backdrop-blur-sm"
            />
          </div>
          <button
            className="p-2 bg-orange text-white text-center rounded flex items-center gap-2 justify-center"
            onClick={handelSignUp}
            disabled={view}
          >
            {loading ? <Spinner /> : ""}
            <span>تکمیل ثبت نام</span>
          </button>
          <button className=" font-bold text-title" onClick={handleResendCode}>
            ارسال مجدد کد
          </button>
        </form>
      )}
    </>
  );
};

export default SignUp;
