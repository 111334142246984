import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { updateMany } from "../../../services/contentServices";
import { GetUsers } from "../../../services/userServices";
import Spinner from "../../includes/Spinner";

const ContentManagerEditDialog = ({ ids ,content }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);

  //* Content Writer and Editor
  const [contentWriter, setContentWriter] = useState("");
  const [contentWriters, setContentWriters] = useState([]);
  const [contentWriterSearch, setContentWriterSearch] = useState("");

  const [contentEditor, setContentEditor] = useState("");
  const [contentEditors, setContentEditors] = useState([]);
  const [contentEditorSearch, setContentEditorSearch] = useState("");

  //* DueDate, ContentLink, finalwordcount
  const [finalWordCount, setFinalWordCount] = useState(undefined);
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;

  const [dueDate, setDueDate] = useState("");
  const [contentLink, setContentLink] = useState("");

  //*  Status
  const [status, setStatus] = useState("");
  const [statusInput, setStatusInput] = useState("");

  const [imageStatus, setImageStatus] = useState("");
  const [imageStatusInput, setImageStatusInput] = useState("");

  useEffect(() => {
    if (content.dueDate) {
      const match = content.dueDate.match(regex);
      
      setDueDate(match ? match[1] : "2024-08-20T17:30:00");
    }

    setStatus(content.status);
    setContentLink(content.contentLink || "");
    setContentWriter(content.writer[0] ? content.writer[0]._id : "");
    setContentEditor(content.editor[0] ? content.editor[0]._id : "");
    setFinalWordCount(content.finalWordCount?content.finalWordCount:"");
    setLoading(false);
  }, []);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const searchUser = async (e, role) => {
    e.preventDefault();

    try {
      const token = user.token;
      if (!token) return;

      switch (role) {
        case "contentWriter":
          const { data: contentWriter } = await GetUsers(token, {
            name: contentWriterSearch,
            content: content._id,
            userRole: "contentTeam",
          });
          setContentWriters(contentWriter.user);
          break;
        case "contentEditor":
          const { data: contentEditor } = await GetUsers(token, {
            name: contentEditorSearch,
            content: content._id,
            userRole: "contentTeam",
          });
          setContentEditors(contentEditor.user);
          break;
      }
      //
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    const token = user.token;
    if (!token) return;
    try {
      setLoading(true);

      const { data } = await updateMany(
        token,

        {
          updateState: "contentManager",
          contentsID: ids,
          update: {
            status: statusInput,
            imageStatus: imageStatusInput,
            contentTeam: {
              writer: contentWriter,
              editor: contentEditor,
            },
            contentLink,
            dueDate: dueDate === "" ? "" : `${dueDate}Z`,
            finalWordCount,
          },
        }
      );

      setLoading(false);
      toast.success("محتوا آپدیت شد");
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="grid grid-cols-12 h-full w-full gap-4 ">
      <div className="col-span-12 flex items-center w-full justify-between ">
        <h2 className=" text-Primary">ویرایش محتوا</h2>
        <button onClick={() => setDialog(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-red-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">موضوع:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            readOnly
            value={content.subject}
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
          />
        </div>
      </div>

      {/* Editor */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">جستجو ویراستار:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            placeholder={
              content.editor && content.editor[0]
                ? content.editor[0].name || content.editor[0].phoneNumber
                : "شماره یا نام ویراستار را وارد کنید."
            }
            onChange={(e) => onChangeHandler(e, setContentEditorSearch)}
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
          />
          <button
            className="bg-green rounded-l-md text-white p-1"
            onClick={(e) => searchUser(e, "contentEditor")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">انتخاب ویراستار:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
            onChange={(e) => onChangeHandler(e, setContentEditor)}
          >
            <option value="" disabled selected>
              گزینه مورد نظر را انتخاب کنید
            </option>
            {contentEditors.map((el) => (
              <option value={el._id}>{el.name || el.phoneNumber}</option>
            ))}
          </select>
        </div>
      </div>
      {/* writer */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">جستجو نویسنده:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setContentWriterSearch)}
            placeholder={
              content.writer && content.writer[0]
                ? content.writer[0].name || content.writer[0].phoneNumber
                : "نام یا شماره تلفن نویسنده را وارد کنید."
            }
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
          />
          <button
            className="bg-green rounded-l-md text-white p-1"
            onClick={(e) => searchUser(e, "contentWriter")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">انتخاب نویسنده:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
            onChange={(e) => onChangeHandler(e, setContentWriter)}
          >
            <option value="" disabled selected>
              گزینه مورد نظر را انتخاب کنید
            </option>
            {contentWriters.map((el) => (
              <option value={el._id}>{el.name || el.phoneNumber}</option>
            ))}
          </select>
        </div>
      </div>

      {/* DuaDate & Final Word Count */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">مهلت تحویل:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setDueDate)}
            type="datetime-local"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
            value={dueDate}
          />
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">تعداد کلمات نهایی:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setFinalWordCount)}
            value={finalWordCount}
            type="number"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
          />
        </div>
      </div>

      {/* Content & Imnage Status */}
      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">وضعیت محتوا:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
            onChange={(e) => {
              onChangeHandler(e, setStatusInput);
            }}
          >
            <option value="" disabled selected>
              گزینه مورد نظر را انتخاب کنید
            </option>
            <option value="" disabled selected>
              گزینه مورد نظر را انتخاب کنید
            </option>{" "}
            <option value="Assing to be Written">ارسال برای نگارش</option>
            <option value="Need rewriting">نیاز به بازنویسی</option>
            <option value="Written">نوشته شده</option>
            <option value="Editor approved">تایید ویراستار</option>
            <option value="Manager does not approved">
              عدم تایید مدیر محتوا
            </option>
            <option value="Manager approved">تاید مدیر محتوا</option>
          </select>
        </div>
      </div>

      <div className="col-span-12 lg:col-span-6 grid grid-cols-2 xs:flex items-center ">
        <p className="col-span-2 xs:w-[180px] text-sm">وضعیت عکس:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <select
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
            onChange={(e) => {
              onChangeHandler(e, setImageStatusInput);
            }}
          >
            <option value="" disabled selected>
              گزینه مورد نظر را انتخاب کنید
            </option>
            <option value="" disabled selected>
              گزینه مورد نظر را انتخاب کنید
            </option>{" "}
            <option value="Assing to be Designed">
              ارسال برای پروسه طراحی
            </option>
            <option value="Manager has not approved Images">
              عدم تایید عکس مدیر محتوا
            </option>
            <option value="Images require redesigning">
              عکس نیاز به طراحی مجدد دارد
            </option>
            <option value="Manager approved images">
              تایید عکس مدیر محتوا
            </option>
          </select>
        </div>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">کلمات کلیدی:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            value={content.keywords}
            readOnly
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
          />
        </div>
      </div>

      <div className="col-span-12  grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">توضیحات :</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <textarea
            value={content.description}
            readOnly
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100 overflow-y-scroll resize-none"
          />
        </div>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center">
        <p className="col-span-2 xs:w-[180px] text-sm">لینک محتوا:</p>
        <div className="flex xs:w-[calc(100%-180px)] col-span-2 w-full">
          <input
            onChange={(e) => onChangeHandler(e, setContentLink)}
            value={contentLink}
            type="text"
            className="p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
          />
        </div>
      </div>
      <div className="col-span-12  grid grid-cols-2 xs:flex items-center pb-10 lg:pb-0">
        <button
          className="w-full bg-green text-white p-2 flex justify-center items-center gap-4 "
          onClick={(e) => updateHandler(e)}
          disabled={loading}
        >
          <p className="text-lg"> ادیت پروژه</p>
          {loading ? <Spinner /> : ""}
        </button>
      </div>
    </div>
  );
};

export default ContentManagerEditDialog;
