import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import EditorEditDialog from "./EditorEditDialog";


const EditorContentsTable = ({
  setContents,
  contents = {},
 
}) => {

  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;

  const [allCheckBox, setAllCheckBox] = useState(false);
  const [checkedList, setCheckedList] = useState([]);




  const checkBoxHandler = (id) => {
    const index = checkedList.findIndex((item) => item.id == id);
    if (index === -1) {
      setCheckedList([...checkedList, { id }]);
    } else {
      const updatedItems = checkedList.filter((item) => item.id !== id);
      setCheckedList(updatedItems);
    }

    const contentsCopy = contents.map((item) =>
      item._id === id ? { ...item, checked: !item.checked } : item
    );
    setContents(contentsCopy);
  };

  const DeselectAll = () => {
    setCheckedList([]);
    const contentsCopy = contents.map((item) => {
      return { ...item, checked: false };
    });
    setContents(contentsCopy);
  };

  const selectAll = () => {
    const updatedItems = [];
    contents.map((item) => updatedItems.push({ id: item._id }));
    setCheckedList(updatedItems);

    const contentsCopy = contents.map((item) => {
      return { ...item, checked: true };
    });
    setContents(contentsCopy);
  };
  const isAllSelected = () => contents.length == checkedList.length;

  const contentStatusConverter = (status = { stage: "", text: "" }) => {
    switch (status.stage) {
      case "Pending Customer":
        if (status.text === "ordered") {
          return "در انتظار ارسال برای نگارش کارفرما";
        }
        if (status.text === "The content is ready") {
          return "در انتظار بررسی کارفرما";
        }
        if (status.text === "The content is ready") {
          return "در انتظار بررسی کارفرما";
        }
        break;
      case "Customer Approved":
        return "تایید کارفرما";

      case "Pending Account Manager":
        return "در انتظار اکانت منیجر";

      case "Pending Writer":
        if (status.text === "Assing to be Written") {
          return "در انتظار نویسنده";
        }
        if (status.text === "The content is currently being written") {
          return "در حال نگارش";
        }
        if (status.text === "The content requires rewriting") {
          return "نیاز به بازنویسی";
        }
        break;

      case "Pending Editor":
        if (status.text === "The content has been written") {
          return "نیار به بررسی ویراستار";
        }
        if (status.text === "The manager does not approve") {
          return "عدم تایید مدیر محتوا";
        }
        if (status.text === "Customer has not approved the content") {
          return "عدم تایید مشتری";
        }
        break;

      case "Pending Content Manager":
        if (
          status.text === "Account Manager Assign Content to Content Manager"
        ) {
          return "اساین شده به مدیر محتوا";
        }
        if (status.text === "The content has been approved by the editor") {
          return "نیار به بررسی مدیر محتوا";
        }
        if (status.text === "The Account manager does not approve") {
          return "عدم تایید اکانت منیجر";
        }
      default:
        return "وضعیت تعیین نشده ";
    }
  };

  const mediaStatusConverter = (status = { stage: "", text: "" }) => {
    switch (status.stage) {
      case "Pending Content Manager":
        if (
          status.text === "The images have been approved by the design manager"
        )
          return "نیاز به بررسی مدیر محتوا";
        break;
        break;
      case "Pending Design Manager":
        if (status.text === "Assing to Design Manager")
          return "اساین شده به مدیر مدیا";

        if (status.text === "Assing to be Designed")
          return "ارسال شده برای پروسه طراحی";

        if (status.text === "The manager has not approved the images")
          return "عدم تایید مدیر محتوا";
        if (
          status.text === "The images have been designed" ||
          status.text === "The images have been redesigned"
        )
          return "نیاز به بررسی مدیر طراحی";
        break;
        break;
      case "Pending Designer":
        if (status.text === "Images are currently being designed")
          return "عکس در حال طراحی است";

        if (status.text === "Images Assigned to be Designed")
          return "عکس ها برای طراحی به گرافیست اساین شدند";

          if (status.text === "The Images Require Redesigning")
          return "عکس ها نیاز به طراحی مجدد دارند";
        break;
      default:
        return "وضعیت تعیین نشده";
    }
  };


  const DateConvertor = (date) => {

    if (!date) return "";
    const match = date.match(regex);
    if (match) return match[1];
  };

  const signleUpdate = (el) => {
    
    setDialog(
      <EditorEditDialog ids={[{_id:el._id}]}  content={el} />
    );
    setDialogClasses(
      "bg-white w-[80%] h-[500px] sm:w-[80%] sm:h-[500px] md:w-[70%] flex flex-col md:h-[600px] lg:h-[600px] rounded p-4 items-center justify-center gap-4 shadow-lg"
    );
  };

  return (
    <div className="flex flex-col   p-2 text-[14px] xl:text-md  mb-6">
      {checkedList.length > 0 ? (
        <div className="w-full flex bg-dark-200 rounded p-2 mb-8 text-white gap-2 items-center justify-between flex-wrap">
          <div className="flex gap-2">
            <button onClick={DeselectAll}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <p>محتوا های انتخاب شده:</p>
            <p>{checkedList.length}</p>
          </div>
          <button
            className="px-2 py-1 flex items-center gap-2 md:animate-bounce font-bold"
            onClick={() => {
              toast.info("این ویژگی به زودی اضافه می شود.");
            }}
          >
            <p> ادیت دسته جمعی: </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </button>
        </div>
      ) : (
        ""
      )}
      <div className=" grid-cols-12 mb-2 hidden lg:grid items-right gap-2">
        <div className="col-span-12  flex  flex-col lg:flex-row lg:items-center gap-2">
          <div className="flex gap-2 lg:w-[40%] pr-2">
            <input
              type="checkbox"
              name=""
              id=""
              checked={isAllSelected()}
              onChange={selectAll}
            />
            <p className="text-Primary">عنوان</p>
          </div>
          <hr className="opacity-50 mb-2 " />
          <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
            <div className="col-span-4 text-center">وضعیت محتوا:</div>
            <div className=" lg:col-span-4 text-center">وضعیت مدیا:</div>
            <div className=" lg:col-span-2 text-center">مهلت:</div>
            <div className=" lg:col-span-2 text-center">عملیات ها:</div>
          </div>
        </div>
      </div>
      <hr className="opacity-50 mb-4 hidden lg:block" />

      <div className="grid grid-cols-12 mb-2 items-right gap-4">
        {contents.map((el) => (
          <div className="col-span-12  flex  flex-col lg:flex-row lg:items-center gap-2 bg-white rounded-lg p-2">
            <div className="flex gap-2 lg:w-[40%]">
              <input
                type="checkbox"
                name=""
                id=""
                checked={el.checked}
                onChange={() => checkBoxHandler(el._id)}
              />
              <p className="text-Primary ">
                {el.subject ? el.subject : "این محتوا فاقد موضوع است."}
              </p>
            </div>
            <hr className="opacity-50 mb-2 " />
            <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
              <div className="flex items-center sm:col-span-6  lg:hidden">
                <p className="w-[150px] ">آدرس سایت:</p>
                <p className="p-1  w-full bg-gray-100 rounded overflow-hidden  text-left">
                  {el.project ? el.project.siteName : "سایت تعریف نشده است"}
                </p>
              </div>
              <div className="flex items-center  sm:col-span-6 lg:col-span-4 lg:text-center">
                <p className="w-[150px] lg:hidden"> وضعیت محتوا:</p>
                <p className="p-1  w-full bg-gray-100 rounded">
                  {contentStatusConverter(el.status)}
                </p>
              </div>
              <div className="flex items-center  sm:col-span-6  lg:col-span-4 lg:text-center">
                <p className="w-[150px] lg:hidden"> وضعیت مدیا:</p>
                <p className="p-1  w-full bg-gray-100 rounded ">
                  {mediaStatusConverter(el.imageStatus)}
                </p>
              </div>
              <div className="flex items-center  sm:col-span-6  lg:col-span-2 lg:text-center w-full">
                <p className="w-[150px] lg:hidden ">مهلت:</p>
                <input
                  className="p-1 w-full bg-gray-100 rounded text-center  lg:text-[1vw] xl:text-sm "
                  type="datetime-local"
                  value={DateConvertor(el.dueDate)}
                  readOnly
                />
              </div>
              <div className="flex items-center  sm:col-span-6  lg:col-span-2">
                <div className="p-1 w-full hidden lg:flex gap-2 justify-center items-center">
                  <Link to={`/contents/editor/${el._id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                      />
                    </svg>
                  </Link>

                  <button onClick={() => signleUpdate(el)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <hr className="opacity-50 mb-2 lg:hidden" />
            <div className="flex gap-2 justify-end items-center  sm:col-span-12 lg:hidden">
              <p className="px-2 py-1 rounded bg-green bg-opacity-15 text-green ">
                ویرایش محتوا
              </p>
              <p className="px-2 py-1 rounded bg-secondary bg-opacity-15 text-secondary ">
                مشاهده محتوا
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditorContentsTable;
