import { useEffect } from "react";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { ViewContext } from "../../context/ViewContext";

const DialogBox = ({forceupdate}) => {
  const { dialog, setDialog, dialogClasses } = useContext(ViewContext);
 
  return (
    <>
      <div className="backdrop-blur-sm  backdrop-contrast-[75%] w-full h-full flex justify-center items-center z-20 absolute ">
        <div className={dialogClasses}>
          {dialog}
        </div>
      </div>
    </>
  );
};

export default DialogBox;
