import { useEffect, useState } from "react";
import { useContext } from "react";
import { ViewContext } from "../../../context/ViewContext";
const HumanResource = ({
  setEditor,
  setWriter,
  writer,
  editor,
  role,
  setContentManager,
  contentManager,
  setMediaManager,
  mediaManager,
  Designer,
  setDesigner,
}) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [writerData, setWriterData] = useState("");
  const [contentManagerData, setContentManagerData] = useState("");
  const [mediaManagerData, setMediaManagerData] = useState("");
  const [DesignerData, setDesignerData] = useState("");
  const [editorData, setEditorData] = useState("");

  useEffect(() => {
    setWriterData(writer);
    setEditorData(editor);
  }, []);

  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">فیلتر بازه زمانی</p>
        <button
          className=" text-red-500"
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex flex-col gap-4 h-full justify-between">
        <div className=" flex flex-col gap-4">
          {role === "accountManager" ? (
            <div className="flex ">
              <p className="w-[150px]">مدیر محتوا:</p>
              <div className="flex w-full">
                <input
                  title="صفر اول شماره را وارد نکنید"
                  type="text"
                  className="w-full bg-gray-100 rounded-r focus:outline-none p-1"
                  value={editorData}
                  placeholder="9xxxxxxxxx"
                  onChange={(e) => {
                    setEditor(e.target.value);
                    setEditorData(e.target.value);
                  }}
                />

                {editorData ? (
                  <button
                    className="bg-red-600 p-1 rounded-sm rounded-l text-white"
                    onClick={() => {
                      setEditor("");
                      setEditorData("");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="flex ">
            <p className="w-[150px]">ویراستار:</p>
            <div className="flex w-full">
              <input
                title="صفر اول شماره را وارد نکنید"
                type="text"
                className="w-full bg-gray-100 rounded-r focus:outline-none p-1"
                value={editorData}
                placeholder="9xxxxxxxxx"
                onChange={(e) => {
                  setEditor(e.target.value);
                  setEditorData(e.target.value);
                }}
              />

              {editorData ? (
                <button
                  className="bg-red-600 p-1 rounded-sm rounded-l text-white"
                  onClick={() => {
                    setEditor("");
                    setEditorData("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="flex ">
            <p className="w-[150px]">نویسنده:</p>
            <div className="flex w-full">
              <input
                title="صفر اول شماره را وارد نکنید"
                placeholder="9xxxxxxxxx"
                onChange={(e) => {
                  setWriter(e.target.value);
                  setWriterData(e.target.value);
                }}
                type="text"
                className="w-full bg-gray-100 rounded-r focus:outline-none p-1"
                value={writerData}
              />

              {writerData ? (
                <button
                  className="bg-red-600 p-1 rounded-sm rounded-l text-white"
                  onClick={() => {
                    setWriter("");
                    setWriterData("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          {role === "accountManager" ? (
            <>
              <div className="flex ">
                <p className="w-[150px]">مدیر مدیا:</p>
                <div className="flex w-full">
                  <input
                    title="صفر اول شماره را وارد نکنید"
                    type="text"
                    className="w-full bg-gray-100 rounded-r focus:outline-none p-1"
                    value={editorData}
                    placeholder="9xxxxxxxxx"
                    onChange={(e) => {
                      setEditor(e.target.value);
                      setEditorData(e.target.value);
                    }}
                  />

                  {editorData ? (
                    <button
                      className="bg-red-600 p-1 rounded-sm rounded-l text-white"
                      onClick={() => {
                        setEditor("");
                        setEditorData("");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex ">
                <p className="w-[150px]">طراح:</p>
                <div className="flex w-full">
                  <input
                    title="صفر اول شماره را وارد نکنید"
                    type="text"
                    className="w-full bg-gray-100 rounded-r focus:outline-none p-1"
                    value={editorData}
                    placeholder="9xxxxxxxxx"
                    onChange={(e) => {
                      setEditor(e.target.value);
                      setEditorData(e.target.value);
                    }}
                  />

                  {editorData ? (
                    <button
                      className="bg-red-600 p-1 rounded-sm rounded-l text-white"
                      onClick={() => {
                        setEditor("");
                        setEditorData("");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <button
          className="p-1 bg-green text-white font-bold rounded"
          onClick={() => setDialog(false)}
        >
          ثبت طلاعات
        </button>
      </div>
    </div>
  );
};

export default HumanResource;
