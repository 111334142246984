import { createContext } from "react";
export const ViewContext = createContext({
  toggle: "false",
  setToggle: () => {},
});

export const DialogContex = createContext({
  dialog: "false",

  setDialog: () => {},
  dialogClasses:"",
  setDialogClasses :()=>{}
});

export const LoadingContex = createContext({
  loading: "false",

  seLoading: () => {},
});

