import { Link } from "react-router-dom";
import moment from "moment-jalaali";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { myOrders } from "../../services/shopServices";
import Pagination from "../includes/Pagination";
import { useContext } from "react";
import { ViewContext } from "../../context/ViewContext";
import GettingDataSpinner from "../includes/Spinners/GettingDataSpinner";
import Order from "./Order";

const Orders = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const [limit, setLimit] = useState(10);
  const [orders, setOrders] = useState([]);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(0);
  const [payedAmount, setPayedAmount] = useState(0);
  const [order, setOrder] = useState({});
  const [modal, setModal] = useState(false);
  useEffect(() => {
    getOrderHandeler();
  }, [user, page, limit]);

  const getOrderHandeler = async () => {
    try {
      const query = `page=${page}&limit=${limit}`;
      const token = user.token;
      if (!token) return;

      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);
      const { data } = await myOrders(token, query);
      setResults(data.results);
      setOrders(data.payments);
      console.log(data);
      if (data.PayedAmount[0]) setPayedAmount(data.PayedAmount[0].payed);

      setDialog(false);
    } catch (error) {
      setDialog(false);
      toast.error(error.response.data.message);
    }
  };

  const dateConverter = (date) => {
    const m = moment(date, "YYYY/M/D HH:mm").format("jYYYY/jM/jD ساعت: HH:mm");
    // const d = new Date(date)
    return `${m}`;
  };

  const OpenOrderHandler = (order) => {
    setDialog(
      <Order order={order}/>
    );
    setDialogClasses(
      "bg-white w-[80%] h-[500px] sm:w-[80%] sm:h-[500px] md:w-[70%] flex flex-col md:h-[600px] lg:h-[600px] rounded p-4 items-center justify-center gap-4 shadow-lg"
    );
  };
  return (
    <div className="container h-full overflow-y-scroll ">
      <div className="flex flex-col   p-2 text-[14px] xl:text-md  mb-6">
        <div className=" grid-cols-12 mb-2 hidden lg:grid items-right gap-2">
          <div className="col-span-12  flex  flex-col lg:flex-row lg:items-center gap-2">
            <div className="flex gap-2 lg:w-[40%] pr-2">
              <p className="text-Primary">تاریخ خرید</p>
            </div>
            <hr className="opacity-50 mb-2 " />
            <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
              <div className=" lg:col-span-3 text-center">مبلغ کل:</div>
              <div className=" lg:col-span-3 text-center">مبلغ پرداختی:</div>
              <div className=" lg:col-span-3 text-center">وضعیت:</div>
              <div className=" lg:col-span-3 text-center">عملیات ها:</div>
            </div>
          </div>
        </div>
        <hr className="opacity-50 mb-4 hidden lg:block" />
        <div className="grid grid-cols-12 mb-2 items-right gap-4 p-2">
          {orders.map((el) => (
            <div className="col-span-12  flex  flex-col lg:flex-row lg:items-center gap-2 bg-white rounded-lg p-2">
              <div className="flex gap-2 lg:w-[40%]">
                <p className="text-Primary ">{dateConverter(el.createdAt)}</p>
              </div>
              <hr className="opacity-50 mb-2 " />
              <div className="lg:w-[60%] flex flex-col gap-2 sm:grid sm:grid-cols-12">
                <div className="flex items-center  sm:col-span-6 lg:col-span-3 lg:text-center">
                  <p className="w-[150px] lg:hidden">مبلغ کل:</p>
                  <p className="p-1  w-full bg-gray-100 rounded">{el.amount}</p>
                </div>
                <div className="flex items-center  sm:col-span-6  lg:col-span-3 lg:text-center">
                  <p className="w-[150px] lg:hidden"> مبلغ پرداختی:</p>
                  <p className="p-1  w-full bg-gray-100 rounded ">
                    {el.payedAmount}
                  </p>
                </div>
                <div className="flex items-center  sm:col-span-6  lg:col-span-3 lg:text-center w-full">
                  <p className="w-[150px] lg:hidden"> وضعیت پرداخت:</p>
                  <p className="p-1  w-full bg-gray-100 rounded ">
                    {" "}
                    {el.status === 100 || el.status === 101
                      ? "پرداخت موفق"
                      : "پرداخت ناموفق"}
                  </p>
                </div>
                <div className="flex items-center  sm:col-span-6  lg:col-span-3 lg:text-center w-full">
                  <p className="w-[150px] lg:hidden"> عملیات ها:</p>
                  <div className="flex gap-2 items-center justify-center sm:col-span-12 w-full ">
                    <button onClick={()=>OpenOrderHandler(el)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <hr className="opacity-50 mb-2 lg:hidden" />
              <div className="flex gap-2 justify-end items-center  sm:col-span-12 lg:hidden">
                <button className="px-2 py-1 rounded bg-secondary bg-opacity-15 text-secondary " onClick={()=>OpenOrderHandler(el)}>
                  مشاهده سفارش
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        results={results}
      />
    </div>
  );
};

export default Orders;
