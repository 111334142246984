import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import { createProject, uploadStructureFile } from "../../../services/projectServices";
import LoadingSpinner from "../../includes/Spinners/LoadingSpinner";

const CreateProject = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const user = useSelector((state) => state.user);


  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState({});

  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
  };
  const onChangeHandler = (e, state) => {
    console.log(e.target.value);
    state(e.target.value);
  };

  const createProjectHandler = async (e) => {
    e.preventDefault();

    try {
      setDialog(<LoadingSpinner />);
      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[600px] flex flex-col md:h-[500px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );

      const formdata = new FormData();

      formdata.append("name", name);
      formdata.append("siteName", website);
      formdata.append("description", description);
      const token = user.token;
      if (!token) return;

      const { data, status } = await createProject(token, formdata);

      if (file.name) {
        const fileData = new FormData();
        fileData.append("project", data.doc._id);
        fileData.append("file", file);

        await uploadStructureFile(token, fileData);
      }
      toast.success("پروژه شما ساخته شد");
      setName("");
      setDescription("");
      setWebsite("");
      setFile({});
      setDialog(false);
    } catch (error) {
      setDialog(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">ساخت پروژه جدید</p>
        <button onClick={() => setDialog(false)} className=" text-red-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]"> نام پروژه:</p>
        <input
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
          onChange={(e) => {
            onChangeHandler(e, setName);
          }}
        />
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">آدرس سایت:</p>
        <input
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
          onChange={(e) => {
            onChangeHandler(e, setWebsite);
          }}
        />
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">فایل ساختار:</p>
        <input
          type="file"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
          onChange={(e) => {
            fileChangeHandler(e, setFile);
          }}
        />
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">توضیحات:</p>
        <textarea
          type="text"
          className="p-2 w-full md:h-[170px] overflow-y-scroll  text-primary outline-none border-none rounded bg-slate-100 resize-none"
          onChange={(e) => {
            onChangeHandler(e, setDescription);
          }}
        />
      </div>
      <button
        className="w-full bg-green text-white p-2 flex justify-center items-center gap-4 "
        onClick={createProjectHandler}
      >
        <p className="text-lg"> ساخت پروژه</p>
      </button>
    </div>
  );
};

export default CreateProject;
