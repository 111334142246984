import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";
import {
  getContentProjects,
  getContents,
} from "../../../services/contentServices";
import DueDateFilter from "../../includes/Filter/DueDateFilter";
import HumanResource from "../../includes/Filter/HumanResource";
import ProjectFilter from "../../includes/Filter/ProjectFilter";
import Pagination from "../../includes/Pagination";
import GettingDataSpinner from "../../includes/Spinners/GettingDataSpinner";
import ManagerContentsTable from "./ManagerContentsTable";

const ContentManagerContents = () => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [refresh, setRefresh] = useState(false);

  const [contents, setContents] = useState([]);
  const [results, setResults] = useState(0);

  const user = useSelector((state) => state.user);

  const [searchName, setSName] = useState("");
  const [searchStatus, setSStatus] = useState("");

  // * search variables
  const [websiteList, setWebsiteList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [statusStage, setStatusStage] = useState("");
  const [statusText, setStatusText] = useState("");

  const [DMQuery, setDMQuery] = useState("");

  const [writer, setWriter] = useState("");
  const [writerFlag, setWriterFlag] = useState(true);

  const [editor, setEditor] = useState("");
  const [editorFlag, setEditorFlag] = useState("");

  const [contentManager, setContentManager] = useState("");
  const [contentManagerFlag, setContentManagerFlag] = useState(true);

  const [designManager, setDesignManager] = useState("");
  const [designManagerFlag, setDesignManagerFlag] = useState(true);

  const [designer, setDesigner] = useState("");
  const [designerFlag, setDesignerFlag] = useState(true);

  const [projectFlag, setProjectFlag] = useState(false);
  const [dueDateFlag, setDueDateFlag] = useState(false);
  const [HRFlag, setHRFlag] = useState(false);
  // *----------------------------------------
  let searchsQuery = `page=${page}&limit=${limit}`;
  let searchObject = {};
  useEffect(() => {
    SearchHandler();
  }, [page, user, limit, refresh]);

  const fetchContent = async (query, filter) => {
    try {
      const token = user.token;
      if (!token) return;

      setDialogClasses(
        "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px] md:w-[400px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4 shadow-lg"
      );
      setDialog(<GettingDataSpinner />);

      const { data } = await getContents(token, query, filter);

      setResults(data.results);
      setContents(data.contents);
      setWebsiteList(data.weblist);

      console.log("websites:", data.weblist);
      setDialog(false);
    } catch (e) {
      setDialog(false);

      toast.error(e.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const SearchHandler = () => {
    if (page > 0) {
      searchsQuery = `page=${page}`;
    } else if (page < 0) {
      searchsQuery = searchsQuery + `page=1`;
    }
    if (searchName != "") searchObject["subject"] = searchName;
    if (projectName != "") searchObject["website"] = projectName;
    if (statusText != "") searchObject["status.text"] = statusText;
    if (statusStage != "") searchObject["status.stage"] = statusStage;
    if (fromDate != "" && toDate != "")
      searchObject["dueDate"] = { gte: fromDate, lte: toDate };

    if (editorFlag !== "") searchObject["editor"] = editorFlag;
    if (editor != "") searchObject["editor"] = editor;

    if (!writerFlag) searchObject["writer"] = writerFlag;
    else if (writer != "") searchObject["writer"] = writer;

    fetchContent(searchsQuery, searchObject);
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const statusFilterHandeler = (status) => {
    switch (status) {
      case "Assing to be Written":
        setStatusStage("Pending Writer");
        setStatusText("Assing to be Written");
        break;

      case "Assign to Content Manager":
        setStatusStage("Pending Content Manager");
        setStatusText("Assign to Content Manager");


        break;
      default:
        setStatusStage("");
        setStatusText("");
    }
  };

  const projectFilterHandler = () => {
    setDialogClasses(
      "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px]  md:w-[600px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4"
    );
    setDialog(
      <ProjectFilter
        role={"contentManager"}
        setProject={setProjectName}
        project={projectName}
      />
    );
  };

  const HumanResourceFilterHandler = () => {
    setDialogClasses(
      "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px]  md:w-[600px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4"
    );
    setDialog(
      <HumanResource
        setEditor={setEditor}
        setWriter={setWriter}
        writer={writer}
        editor={editor}
        role="contentManager"
      />
    );
  };

  const DueDateFilterHandler = () => {
    setDialogClasses(
      "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px]  md:w-[600px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4"
    );
    setDialog(
      <DueDateFilter
        setFromDate={setFromDate}
        setToDate={setToDate}
        toDate={toDate}
        fromDate={fromDate}
      />
    );
  };

  return (
    <div className="h-full overflow-y-scroll ">
      <div className="p-2 mb-8 text-sm">
        <div className="p-2 w-full flex gap-4 flex-wrap rounded bg-white justify-between items-center mb-4">
          <div className="col-span-7 sm:col-span-3  lg:col-span-3 flex items-center gap-2">
            <p>عنوان</p>
            <input
              onChange={(e) => onChangeHandler(e, setSName)}
              type="text"
              className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100"
            />
          </div>
          <div class="col-span-7 sm:col-span-4 lg:col-span-3 flex items-center gap-2">
            <p>وضعیت:</p>
            <select
              class="p-2 w-full lg:w-auto  xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100 overflow-y-scroll "
              onChange={(e) => {
                statusFilterHandeler(e);
              }}
            >
              <option value="item-1" disabled="">
                وضعیت مورد نظر خود را انتخاب کنید.
              </option>
              <option value="">همه محتوا ها</option>

              {/* نویسنده */}

              <option value="Assing to be Written">
                ارسال شده برای نویسنده
              </option>
              <option value="The content requires rewriting">
                نیاز به بازنویسی
              </option>
              <option value="The content is currently being written">
                در حال نگارش
              </option>
              <option value="Customer has not approved the content">
                عدم تایید مشتری
              </option>

              {/* ویراستار */}

              <option value="The content has been written">
                در انتظار بررسی ویراستار
              </option>
              <option value="Manager does not approved">
                عدم تایید مدیر محتوا
              </option>

              <option value="Needs Editor's Rewrite">
                نیاز به بازنویسی ویراستار
              </option>

              {/* مدیر محتوا */}

              <option value="Assigned to Content Manager">
                اساین شده به مدیر محتوا
              </option>
              <option value="The content has been approved by the editor">
                در انتظار بررسی مدیر محتوا
              </option>
              <option value="The Content is Currently beeing written by the Editor">
                در حال نگارش ویراستار
              </option>

              {/* اکانت منیجر */}
              <option value="The Account manager does not approve">
                عدم تایید مدیر محتوا
              </option>
              <option value="Customer send content to be Written">
                در انتظار اکانت منیجر برای نگارش
              </option>

              {/* کارفرما */}
              <option value="Customer Approved">تایید کارفرما</option>
              <option value="The content is ready">ارسال برای کارفرما</option>
            </select>
          </div>
          <div className="flex gap-2 flex-wrap">
            <button
              className="py-1 px-4 bg-Primary text-white rounded"
              onClick={() => {
                setRefresh(!refresh);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
            <button
              className="py-1 px-4 bg-green text-white rounded"
              onClick={() => SearchHandler()}
            >
              جستجو
            </button>
          </div>
        </div>
        <div className="p-2 w-full flex gap-4 flex-wrap rounded  items-center">
          <button
            className="col-span-7 lg:col-span-1 px-[10px] py-[2px] bg-green text-white rounded"
            onClick={projectFilterHandler}
          >
            فیلتر پروژه ها
          </button>
          <button
            className="col-span-7 lg:col-span-1 px-[10px] py-[2px] bg-cyan-500 text-white rounded"
            onClick={DueDateFilterHandler}
          >
            فیتلر بازه زمانی
          </button>
          <button
            className="col-span-7 lg:col-span-1 px-[10px] py-[2px] bg-orange text-white rounded"
            onClick={HumanResourceFilterHandler}
          >
            فیلتر منابع انسانی
          </button>
        </div>
      </div>
      <ManagerContentsTable
        setDialog={setDialog}
        setDialogClasses={setDialogClasses}
        userRole={"contentTeam"}
        contents={contents}
        setContents={setContents}
      />
      <Pagination
        results={results}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
      />
    </div>
  );
};

export default ContentManagerContents;
