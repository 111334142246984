import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";

import AccountManagerContent from "./components/contents/accountManager/AccountManagerContent";
import ContentManagerContent from "./components/contents/contentManager/ContentManagerContent";

import EditorContent from "./components/contents/editor/EditorContent";

import MediaManagerContent from "./components/contents/mediaManager/MediaManagerContent";
import MediaTeamContent from "./components/contents/mediaTeam/MediaTeamContent";

import WriterContent from "./components/contents/writer/WriterContent";

import CustomerContent from "./components/contents/customer/CustomerContent";
import CustomerContents from "./components/contents/customer/CustomerContents";

import NotFound from "./components/errors/NotFound";
import DialogBox from "./components/includes/DialogBox";
import Spinner from "./components/includes/Spinner";
import GettingDataSpinner from "./components/includes/Spinners/GettingDataSpinner";
import Project from "./components/projects/Project";
import Projects from "./components/projects/Projects";
import { ViewContext } from "./context/ViewContext";
import AuthLayouts from "./Layouts/AuthLayouts";
import DashLayout from "./Layouts/DashLayout";
import Shop from "./components/shop/Shop";
import Product from "./components/shop/Product";
import Card from "./components/shop/Card";
import Orders from "./components/shop/Orders";
import EditorContents from "./components/contents/editor/EditorContents";
import WriterContents from "./components/contents/writer/WriterContents";
import AccountManagerContents from "./components/contents/accountManager/AccountManagerContents";
import ContentManagerContents from "./components/contents/contentManager/ContentManagerContents";
import MediaManagerContents from "./components/contents/mediaManager/MediaManagerContents";
import MediaTeamContents from "./components/contents/mediaTeam/MediaTeamContents";
import Logout from "./components/auth/Logout";

function App() {
  const user = useSelector((state) => state.user);

  const [toggle, setToggle] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogClasses, setDialogClasses] = useState("");

  useEffect(() => {
    protect();
  }, [user]);

  const protect = () => {
    if (window.location.pathname.startsWith("/?logout=true")) return;
    setDialogClasses(
      "bg-white w-[80%] h-[400px] sm:w-[400px] sm:h-[400px]  md:w-[600px] flex flex-col md:h-[400px] rounded p-4 items-center justify-center gap-4"
    );
    setDialog(<GettingDataSpinner classes={"h-10 w-10"} />);

    if (window.location.pathname.startsWith("/auth")) return setDialog(false);

    if (window.location.pathname === "/") return setDialog(false);

    if (!user.token) return;

    return setDialog(false);
  };

  return (
    <ViewContext.Provider
      value={{
        toggle: toggle,
        setToggle: setToggle,
        dialog: dialog,
        setDialog: setDialog,
        dialogClasses: dialogClasses,
        setDialogClasses: setDialogClasses,
      }}
    >
      <div className="w-full overflow-hidden">
        {dialog ? <DialogBox /> : ""}

        <div className=" h-[100vh] flex overflow-hidden md:p-4 lg:p-8 gap-2 relative ">
          <BrowserRouter>
            <Routes>
              <Route path="/" Component={DashLayout}>
                <Route path="/contents" Component={CustomerContents} />
                <Route path="/contents/user/:id" Component={CustomerContent} />
                <Route path="/contents/writer/:id" Component={WriterContent} />
                <Route path="/contents/editor/:id" Component={EditorContent} />
                <Route
                  path="/contents/content-manager/:id"
                  Component={ContentManagerContent}
                />
                <Route
                  path="/contents/account-manager/:id"
                  Component={AccountManagerContent}
                />
                <Route
                  path="/contents/media-manager/:id"
                  Component={MediaManagerContent}
                />

                <Route
                  path="/contents/media-team/:id"
                  Component={MediaTeamContent}
                />
                
                 <Route
                  path="/contents/media-team/"
                  Component={MediaTeamContents}
                />
                <Route
                  path="/contents/media-manager"
                  Component={MediaManagerContents}
                />

                <Route path="/projects" Component={Projects} />
                <Route path="/projects/:id" Component={Project} />

                <Route path="/shop" Component={Shop} />
                <Route path="/shop/:slug" Component={Product} />

                <Route path="/card/" Component={Card} />
                <Route path="/orders/" Component={Orders} />

                <Route path="/contents/editor" Component={EditorContents} />
                <Route path="/contents/editor/:id" Component={EditorContent} />

                <Route path="/contents/writer" Component={WriterContents} />
                <Route path="/contents/writer/:id" Component={WriterContent} />

                <Route
                  path="/contents/account-manager"
                  Component={AccountManagerContents}
                />
                <Route
                  path="/contents/account-manager/:id"
                  Component={AccountManagerContent}
                />
                <Route
                  path="/contents/content-manager"
                  Component={ContentManagerContents}
                />
 <Route
                  path="/contents/content-manager/:id"
                  Component={ContentManagerContent}
                />
               
              </Route>
              <Route path="/auth" Component={AuthLayouts}>
                <Route path="/auth/login" Component={Login} />
                <Route path="/auth/sign-up" Component={SignUp} />
                <Route
                  path="/auth/forget-password"
                  Component={ForgotPassword}
                />
              </Route>
              {/* <Route path="/logout" Component={LogOut} /> */}
              <Route Route path="*" exact={true} Component={NotFound} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </ViewContext.Provider>
  );
}

export default App;
