import axios from "axios";

const SERVER_URL = "https://api.modirecontent.com/api/v1";
// const SERVER_URL = "http://127.0.0.1:5000/api/v1";

export const createProject = (token, data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects`;
  return axios.post(url, data);
};

export const editProject = (token, data, id) => {
  console.log("id:",id);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects/${id}`;
  return axios.patch(url, data);
};

export const getMyProject = (token, id, searchsrt) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects/${id}?${searchsrt}`;
  return axios.get(url);
};

export const getMyProjects = (token, query) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects?${query}`;
  return axios.get(url);
};

export const uploadStructureFile = (token, formData) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects/upload-file`;
  return axios.post(url, formData);
};

export const getAllProjects = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects/all-projects`;
  return axios.get(url);
};

export const getaProject = (token, id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects/get-project/${id}`;
  return axios.get(url);
};

export const getStrutureFile = (token, id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects/download-file/${id}`;
  return axios.get(url);
};
export const getProjectContent = (token, id) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const url = `${SERVER_URL}/projects/contents/`;
  return axios.post(url, { id });
};
