import { useEffect } from "react";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";

const DueDateFilter = ({ setToDate, setFromDate, toDate, fromDate }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const currentDate = new Date();
  const previous30Date = new Date();
  const previous90Date = new Date();
  const previous180Date = new Date();
  previous30Date.setDate(currentDate.getDate() - 30);
  previous90Date.setDate(currentDate.getDate() - 90);
  previous180Date.setDate(currentDate.getDate() - 180);

  const [selectedDate, setSelectedDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  useEffect(() => {
    setSelectedDate(fromDate);
    setSelectedToDate(toDate);
    console.log(selectedDate, "=>", selectedToDate);
  }, []);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">فیلتر منابع انسانی</p>
        <button
          className=" text-red-500"
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className=" flex flex-col gap-4 ">
        <div className="flex items-center">
          <label className="w-[150px]" for="">
            از تاریخ:{" "}
          </label>
          <input
            onChange={(e) => onChangeHandler(e, setSelectedDate)}
            type="date"
            className=" p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
            value={selectedDate}
          />
        </div>
        <div className="flex items-center">
          <label for="" className=" w-[150px]">
            تا تاریخ:{" "}
          </label>
          <input
            className=" p-2 text-primary w-full outline-none border-none rounded bg-slate-100"
            type="date"
            onChange={(e) => onChangeHandler(e, setSelectedToDate)}
            value={selectedToDate}
          />
        </div>
      </div>
      <div className=" flex flex-col gap-5 mb-2">
        <button
          id="30Day"
          className={`p-1  ${
            new Date(toDate).getTime() - new Date(fromDate).getTime() ==
            2592000000
              ? "bg-green"
              : "bg-orange"
          } text-white font-bold rounded`}
          onClick={() => {
            setFromDate(formatDate(previous30Date));
            setSelectedDate(formatDate(previous30Date));
            setToDate(formatDate(currentDate));
            setSelectedToDate(formatDate(currentDate));
            document.getElementById("30Day").classList.remove("bg-orange");
            document.getElementById("30Day").classList.add("bg-green");

            document.getElementById("60Day").classList.remove("bg-green");
            document.getElementById("60Day").classList.add("bg-orange");

            document.getElementById("90Day").classList.remove("bg-green");
            document.getElementById("90Day").classList.add("bg-orange");
          }}
        >
          یک ماه
        </button>
        <button
          id="60Day"
          className={`p-1  ${
            new Date(toDate).getTime() - new Date(fromDate).getTime() ==
            7776000000
              ? "bg-green"
              : "bg-orange"
          } text-white font-bold rounded`}
          onClick={() => {
            setFromDate(formatDate(previous90Date));
            setSelectedDate(formatDate(previous90Date));
            setToDate(formatDate(currentDate));
            setSelectedToDate(formatDate(currentDate));

            document.getElementById("60Day").classList.remove("bg-orange");
            document.getElementById("60Day").classList.add("bg-green");

            document.getElementById("30Day").classList.remove("bg-green");
            document.getElementById("30Day").classList.add("bg-orange");

            document.getElementById("90Day").classList.remove("bg-green");
            document.getElementById("90Day").classList.add("bg-orange");
          }}
        >
          سه ماه
        </button>
        <button
          id="90Day"
          className={`p-1  ${
            new Date(toDate).getTime() - new Date(fromDate).getTime() ==
            15552000000
              ? "bg-green"
              : "bg-orange"
          } text-white font-bold rounded`}
          onClick={() => {
            setFromDate(formatDate(previous180Date));
            setSelectedDate(formatDate(previous180Date));

            setToDate(formatDate(currentDate));
            setSelectedToDate(formatDate(currentDate));

            document.getElementById("90Day").classList.remove("bg-orange");
            document.getElementById("90Day").classList.add("bg-green");

            document.getElementById("30Day").classList.remove("bg-green");
            document.getElementById("30Day").classList.add("bg-orange");

            document.getElementById("60Day").classList.remove("bg-green");
            document.getElementById("60Day").classList.add("bg-orange");
          }}
        >
          شیش ماه
        </button>

        <button
          className="p-1 bg-red-700  text-white font-bold rounded"
          onClick={(e) => {
            setFromDate("");
            setSelectedDate("");

            setToDate("");
            setSelectedToDate("");

            document.getElementById("30Day").classList.remove("bg-green");
            document.getElementById("30Day").classList.add("bg-orange");

            document.getElementById("60Day").classList.remove("bg-green");
            document.getElementById("60Day").classList.add("bg-orange");

            document.getElementById("90Day").classList.remove("bg-green");
            document.getElementById("90Day").classList.add("bg-orange");
          }}
        >
          حذف تاریخ
        </button>
      </div>
    </div>
  );
};

export default DueDateFilter;
