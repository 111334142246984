import { useEffect } from "react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ViewContext } from "../../../context/ViewContext";
import { updateMany } from "../../../services/contentServices";
import Spinner from "../../includes/Spinner";

const EditorEditDialog = ({ ids = [], content = {} }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(ids);
  }, []);

  const onChangeHandler = (e, state) => {
    console.log(e.target.value);
    state(e.target.value);
  };
  const submithandler = async () => {
   
    try {
      if (status === "") return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
      const token = user.token;

      if (!token) return;
      setLoading(true)

      const { data } = await updateMany(token, {
        updateState: "editor",
        contentsID: ids,
        update: {
          status: status,
        },
      });
      if(data.contents.n ==0){ toast.info("هیچ محتوایی آپدیت نشد")}else{
        toast.success(`محتوا آپدیت شد: ${data.contents.n} عدد`);
      }
     
      setStatus(status);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    

      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">ادیت محتوا</p>
        <button
          className=" text-red-500"
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]"> شماره شاخص:</p>
        <input
          value={content._id}
          readOnly
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
        />
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">موضوع:</p>
        <input
          readOnly
          placeholder={content.subject}
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
        />
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]"> وضعیت:</p>
        <select
          name=""
          id=""
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
          onChange={(e)=>onChangeHandler(e,setStatus)}
        >
          <option value="" selected disabled>
            لطفا یک وضعیت را انتخاب کنید.
          </option>
          <option value="Editor Approved">تایید ویراستار</option>
          <option value="Need rewriting">نیاز به بازنویسی</option>
        </select>
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">کلمات کلیدی:</p>
        <input
          readOnly
          placeholder={content.keywords}
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
        />
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">توضیحات:</p>
        <textarea
          readOnly
          placeholder={content.description}
          type="text"
          className="p-2 w-full md:h-[170px]  overflow-y-scroll  text-primary outline-none border-none rounded bg-slate-100 resize-none"
        />
      </div>
      <button className="w-full bg-green text-white p-2 flex justify-center items-center gap-4 " onClick={submithandler} disabled={loading}>
        {loading?(<Spinner/>):""}
        <p className="text-lg"> ویرایش محتوا</p>
      </button>
    </div>
  );
};

export default EditorEditDialog;
