import { useEffect } from "react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ViewContext } from "../../../context/ViewContext";

import Spinner from "../../includes/Spinner";

import {
  updateContentProject,
  updateHandler as contentUpdateHandler,
  statusHandler as updateCustomerStatus,
  updateManyV2,
} from "../../../services/contentServices";

const CustomerEditDialog = ({ ids, content = {}, projects = [] }) => {
  const { setDialog, setDialogClasses } = useContext(ViewContext);

  const [projectloading, setProjectLoading] = useState(false);
  const [statusloading, setStatusLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");

  const [status, setStatus] = useState("");

  const [newStatus, setNewStatus] = useState("");

  const [subject, setSubject] = useState("");
  const [project, setProject] = useState({});
  const [projectID, setProjectID] = useState("");

  useEffect(() => {
    setSubject(content.subject || "");
    setKeywords(content.keywords || "");
    setDescription(content.description || "");
  }, []);

  const onChangeHandler = (e, state) => {
    console.log(e.target.value);
    state(e.target.value);
  };
  const trimHandler = (state) => {
    const text = state;
    return text.trim() === "";
  };
  const updateHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      setLoading(true);
      if (
        trimHandler(keywords) &&
        trimHandler(description) &&
        trimHandler(subject) &&
        trimHandler(status)
      ) {
        setLoading(false);
        return toast.info("شما برای آپدیت حداقل یک فیلد را باید پر کنید.");
      }

      const { data } = await updateManyV2(token, {
        contentsID: ids,
        updateState: "customer",
        update: {
          keywords,
          subject,
          description,
          status: newStatus,
        },
      });
      toast.success("محتوا اپدیت شد");
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.warning(error.response.data.message);
      // toast.warning(error.message);
    }
  };

  const projectHandler = async (e) => {
    e.preventDefault();
    if (projectID === "") return toast.info("لطفا ابتدا یک پروژه انتخاب کنید");

    if (projectID === project.id)
      return toast.info("محتوا در پروژه انتخابی شما است و نیازی به آپدیت نیست");

    try {
      const token = user.token;
      if (!token) return;
      setProjectLoading(true);
      const { data } = await updateContentProject(token, {
        project: projectID,
        id: content._id,
      });
      setProject({
        id: data.content.project._id,
        name: data.content.project.name,
      });

      toast.success("پروژه محتوا آپدیت شد");
      setProjectLoading(false);
    } catch (error) {
      setProjectLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const statusHandler = async (e) => {
    e.preventDefault();
    try {
      if (newStatus === "")
        return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
      const token = user.token;
      if (!token) return;
      setStatusLoading(true);
      const { data } = await updateCustomerStatus(token, {
        status: newStatus,
        id: content._id,
      });
      toast.success("محتوا آپدیت شد");

      setStatus(newStatus);
      setStatusLoading(false);
    } catch (error) {
      setStatusLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="flex flex-col h-full w-full gap-4">
      <div className="flex w-full justify-between mb-4">
        <p className="text-lg text-Primary">ادیت محتوا</p>
        <button
          className=" text-red-500"
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]"> شماره شاخص:</p>
        <input
          value={content._id}
          readOnly
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
        />
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">موضوع:</p>
        <input
          onChange={(e) => onChangeHandler(e, setSubject)}
          placeholder={content.subject}
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
        />
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]"> پروژه:</p>
        <div className="flex w-full">
          <select
            onChange={(e) => {
              onChangeHandler(e, setProjectID);
            }}
            name=""
            id=""
            className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
          >
            <option value="" selected disabled>
              لطفا یک پروژه را انتخاب کنید.
            </option>

            {projects.map((el) => (
              <option value={`${el._id}`}>{el.name}</option>
            ))}
          </select>
          <button
            disabled={projects.length < 1 || projectloading}
            onClick={projectHandler}
            className="bg-green px-2 rounded-l text-white flex items-center gap-1 w-[120px] justify-center"
          >
            {projectloading ? <Spinner /> : <span>ثبت پروژه</span>}
          </button>
        </div>
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]"> وضعیت:</p>
        <div className="flex w-full">
          <select
            onChange={(e) => {
              onChangeHandler(e, setNewStatus);
            }}
            name=""
            id=""
            className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
          >
            <option value="" selected disabled>
              لطفا یک پروژه را انتخاب کنید.
            </option>

            {content.status.stage === "Pending Customer" &&
            content.status.text === "ordered" ? (
              <option value="submit">ارسال برای نگارش</option>
            ) : (
              <>
                {" "}
                <option value="Customer not Approved"> نیاز به بازنویسی</option>
                <option value="Customer Approved">تایید محتوا</option>
              </>
            )}
          </select>
         
        </div>
      </div>

      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">کلمات کلیدی:</p>
        <input
          onChange={(e) => onChangeHandler(e, setKeywords)}
          placeholder={content.keywords}
          type="text"
          className="p-2 w-full  text-primary outline-none border-none rounded bg-slate-100"
        />
      </div>
      <div className="flex items-center gap-2 w-full ">
        <p className="w-[150px]">توضیحات:</p>
        <textarea
          onChange={(e) => onChangeHandler(e, setDescription)}
          placeholder={content.description}
          type="text"
          className="p-2 w-full md:h-[170px]  overflow-y-scroll  text-primary outline-none border-none rounded bg-slate-100 resize-none"
        />
      </div>
      <button
        className="w-full bg-green text-white p-2 flex justify-center items-center gap-4 "
        onClick={updateHandler}
        disabled={loading}
      >
        {loading ? <Spinner /> : ""}
        <span> ادیت اطلاعات محتوا</span>
      </button>
    </div>
  );
};

export default CustomerEditDialog;
