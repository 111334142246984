import { useContext, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../app/store";
import MobileMenu from "../components/includes/MobileMenu";
import SideBar from "../components/includes/SideBar";
import { ViewContext } from "../context/ViewContext";
import { setUser } from "../features/user/userSlice";
import { getMe } from "../services/authServices";

const Dashboard = () => {
  const { toggle, setToggle } = useContext(ViewContext);
  const products = useSelector((state) => state.card.products);
  const cookies = new Cookies();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    protect();
  }, []);

  const protect = async () => {
    if (window.location.pathname.startsWith("/?logout=true")) return;
    if (!user.token) {
      if (!cookies.get("auth")) {
        window.location.replace("/auth/login");

        setUser({
          name: "",
          token: "",
          photo: "",
          role: "",
          id: "",
        });
        cookies.remove("auth");
      } else {
        try {
          const token = cookies.get("auth");
          const { data } = await getMe(token);

          store.dispatch(
            setUser({
              name: data.user.name || data.user.phoneNumber,
              token: cookies.get("auth"),
              photo: data.user.photo,
              role: data.user.role,
              id: data.user._id,
              email: data.user.email,
              website: data.user.website,
            })
          );
        } catch (error) {
          toast.error(error.response.data.message);
          setUser({
            name: "",
            token: "",
            photo: "",
            role: "",
            id: "",
            email: "",
            website: "",
          });
          cookies.remove("auth");
          window.location.replace("/auth");
        }
      }
    } else {
      // const data = await getinitialData(user.token);
      // console.log(data);
      // toast.success("داده ها دریافت شدند.");
    }
  };

  return (
    <>
      <SideBar />
      <MobileMenu />
      <div
        className={`w-full rounded-lg p-4  transition-all	 ease-in-out duration-[.3s]`}
      >
        <div className="flex justify-between mb-4">
          <button onClick={() => setToggle(!toggle)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              {toggle ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              )}
            </svg>
          </button>
          <div className="flex gap-4">
            <Link
              to="/card"
              title="سبد خرید"
              className="px-[15px] py-[5px] bg-white rounded relative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              <p className="bg-orange absolute px-[7px] bottom-[4px] pt-[2px] right-2 text-[10px] text-white rounded-full">
                {products.length}
              </p>
            </Link>
            <button
              title="حساب کاربری"
              className="flex items-center gap-2 p-[5px] bg-white rounded"
            >
              {console.log(user.photo)}
              <img
                src={
                  user.photo !== "user.png" && user.photo !== ""
                    ? `https://api.modirecontent.com/img/users/${user.photo}`
                    : "/img/profile-placeholder.jpg"
                }
                alt=""
                className="h-6 w-6 rounded-full"
              />
              <div className="text-right">
                <p className="text-primary text-[12px]">{user.name}</p>
                <p className="text-orange text-[10px]">خوش آمدید</p>
              </div>
            </button>
          </div>
        </div>

        <Outlet />
      </div>
    </>
  );
};

export default Dashboard;
