import { useEffect } from "react";
import { useContext } from "react";
import { ViewContext } from "../../context/ViewContext";

const Order = ({ order = [] }) => {
  const { setDialog } = useContext(ViewContext);
  useEffect(() => {
    console.log(order);
  });
  return (
    <div className="flex flex-col p-2 w-full h-full gap-8 overflow-y-scroll">
      <div className="flex justify-between items-center ">
        <h2>جزعیات سفارش</h2>
        <button
          onClick={() => {
            setDialog(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-3">
        <div className=" col-span-1 text-center">تعداد سفارش:</div>
        <div className=" col-span-1 text-center">تعداد کلمات:</div>
        <div className=" col-span-1 text-center">نوع محصول:</div>
      </div>
      {order.product.map((el) => (
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-1 text-center">
            <p className="w-[150px] lg:hidden"> تعداد سفارش:</p>
            <p className="p-1  w-full bg-gray-100 rounded ">{el.count}</p>
          </div>

          <div className="col-span-1 text-center">
            <p className="w-[150px] lg:hidden"> تعداد سفارش:</p>
            <p className="p-1  w-full bg-gray-100 rounded ">{el.wordCount}</p>
          </div>

          <div className="col-span-1 text-center">
            <p className="w-[150px] lg:hidden"> تعداد سفارش:</p>
            <p className="p-1  w-full bg-gray-100 rounded ">
              {el.product === "content" ? "تولید محتوا" : ""}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Order;
